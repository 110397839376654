<template>
  <widget
    :id="id"
    :app-id="appId"
    :title="title"
    :stretch="stretch"
    :type="type"
    v-bind="$attrs"
    class="list-container-widget"
    :body-class="bodyClass"
    title-class="d-flex justify-content-between"
  >

    <template #title="slotProps">
      <slot name="title" v-bind="slotProps">
        {{ slotProps.title }}
        <!-- Description addon -->
        <b-button
          v-if="!individualChat"
          variant="link"
          class="p-0 link-button"
          @click="isAppInfoModalVisible = true"
        >
          <feather-icon icon="InfoIcon" size="16" />
        </b-button>
        <description-addon v-model="isAppInfoModalVisible" :app-id="appId" :title="slotProps.title" />
      </slot>
    </template>
    
    <!-- Open list -->
    <template #header-actions >

      <div v-if="showActions" id="maximize-tooltip" class="text-primary">
        <b-link 
          v-if="isLinkVisible" 
          :to="viewMoreLocation"
        >
          <b-badge
            v-b-tooltip.hover
            v-badge-smart-text
            pill
            variant="primary"
            class="text-capitalize mb-0 ml-1 mt-75 check-button"
          >
            {{ $t('common.full-screen') }}
          </b-badge>
        </b-link>

        <!-- Add new -->
        <b-link 
          v-if="isLinkVisible && isNexos" 
          :to="viewMoreLocationExtended"
          class="ml-1"
        >
          <feather-icon
            v-b-tooltip.hover
            :title="$t('tooltips.widget-actions.add')"
            icon="PlusIcon"
            variant="primary"
            size="20"
          />
        </b-link>
      </div>
    </template>
    <template #header-newactions>
      <slot name="actions" />
    </template>
    <slot />
  </widget>
</template>

<script>
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import Widget from './Widget.vue';
import DescriptionAddon from './DescriptionAddon.vue';

// import { ref } from '@vue/composition-api';
// import { useElementVisibility } from '@vueuse/core'

export default {
  name: 'ListContainerWidget',

  components: {
    Widget,
    DescriptionAddon
  },

  props: {
    appId: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: '',
    },
    viewMoreLocation: {
      type: Object,
      required: false,
    },
    hiddenActions:{
      type: Boolean,
      required: false,
    },
    disableViewMore: Boolean,
    size: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: null
    },
    bodyClass: {
      type: String,
      default: '',
    },
    isStaff: {
      type: Boolean,
      required: false,
    },
    stretch: Boolean,
    individualChat: Boolean,
  },
  data() {
    return {
      isAppInfoModalVisible: false,
    };
  },
  computed: {
    isLinkVisible() {
      return !this.disableViewMore && this.size > 0;
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos';
    },
    widget() {
      const app = Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).filter((item) => {
        if (item.id === this.appId) {
          return item.name;
        }
      });
      return app[0];
    },
    showActions() {
      return this.hiddenActions && this.appId !== 8 && this.appId !== 45;
    },
    viewMoreLocationExtended() {
      return this.viewMoreLocation + '?action=create-new';
    }
  },
  
  // watch: {
  //   targetIsVisible: {
  //     // immediate: true,
  //     handler (newValue) {
  //       console.log("++++++++++++ --> WATCH!!! ListContainerWidget.vue (targetIsVisible): ", newValue, this.widget.nameSingular)
  //       // if (newValue && !this.itemsData.length) {
  //       //   console.log("2 --> WATCH!!! ListContainerWidget.vue (targetIsVisible): ", newValue)
  //       //   // this.loadAllData()
  //       // }
  //     }
  //   },
  // },

  methods: {
    translateTranslationTable,
  },
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "~@core/scss/base/components/_variables.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.widget-link {
  margin-right: $card-spacer-x;
  margin-top: 0.5rem;
  float: right;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

// Used to scroll to the proper position when an anchor is used.
[id].list-container-widget {
  scroll-margin-top: $navbar-height;
}
.link-button:focus {
  outline: none;
  box-shadow: none;
}
</style>
